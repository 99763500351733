import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Bearbeitungsgebuehr = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Die Bearbeitungsgebühr – ein Überblick über die Situation in Österreich"
                showCalc={false}
            />
            <Article>
                <p>
                    Obwohl sie in Deutschland schon abgeschafft wurde, ist die Bearbeitungsgebühr bei Krediten in
                    Österreich bei so gut wie allen Kreditinstituten Standard. Nur beim Kontokorrentkredit gibt es
                    manchmal Ausnahmen, ansonsten musst du eigentlich immer mit einer Art Bearbeitungsgebühr rechnen. Ob
                    sie nun direkt als solche bezeichnet wird, oder die Banken von Bereitstellungsentgelt sprechen – die
                    Gebühr ist Teil der Nebenkosten, die bei der Kreditaufnahme anfallen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum ist eine Bearbeitungsgebühr zu zahlen?</h2>
                <p>
                    Die Banken verlangen eine Gebühr für den Arbeitsaufwand, den sie betreiben, um dir deinen Kredit
                    bereitzustellen. Ob diese Bearbeitungsgebühr berechtigt ist oder nicht, ist schon seit Jahren ein
                    strittiges Thema. Schon oft gab es in Österreich Versuche in Richtung Abschaffung. Momentan sieht es
                    jedoch so aus, als ob sie bleiben würden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Wie hoch ist die Bearbeitungsgebühr?</h2>
                <p>
                    Die Höhe der Bearbeitungsgebühr und die Art ihrer Berechnung müssen vorher klar und offen von deiner
                    Bank kommuniziert werden. Manche Banken haben einen fixen Kostensatz, andere verlangen einen
                    prozentualen Anteil deiner Kreditsumme. Bis zu einem gewissen Grad ist die Bearbeitungsgebühr auch
                    verhandelbar. Dabei ist es wichtig, dass du im Laufe der Verhandlungen darauf achtest, dass auch
                    dein effektiver Jahreszinssatz sinkt. Als Teil der Kaufnebenkosten ist die Bearbeitungsgebühr im
                    effektiven Jahreszinssatz deiner Finanzierung schon mitberücksichtigt. Wenn du die
                    Bearbeitungsgebühr zwar senken kannst, dadurch aber andere Nebenkosten höher werden, ist die
                    Verhandlung kein großer Erfolg.
                </p>
                <hr />

                <h2>Was gilt es zu beachten?</h2>
                <p>
                    Wenn du Kredite miteinander{" "}
                    <Link to="/dienstleistungen/kreditvergleich/" target="_blank" rel="noreferrer noopener">
                        vergleichst
                    </Link>
                    , ist es wichtig, alle Faktoren mit einzubeziehen. Am aussagekräftigsten ist aber der effektive
                    Jahreszinssatz. Dieser umfasst nämlich neben dem Normalzinssatz auch alle Nebenkosten deines
                    Kredits, die im Vertrag aufgelistet sind. Deshalb ist der Jahreszinssatz immer höher. Generell sind
                    Nebenkosten jedoch immer verhandelbar und somit auch der Jahreszinssatz nicht von Anfang an
                    beschlossene Sache. Da es aber sehr viele Nebenkosten gibt, ist es nicht ganz einfach, den Überblick
                    zu behalten. Um dir dabei zu helfen, diesen zu bekommen, haben wir einen eigenen Beitrag zum Thema
                    verfasst. Den findest du{" "}
                    <Link to="/artikel/kaufnebenkosten/" target="_blank" rel="noreferrer noopener">
                        hier
                    </Link>
                    .
                </p>
                <hr />

                <h2>Wie und wann zahlt man die Bearbeitungsgebühr?</h2>
                <p>
                    Die Zahlung der Bearbeitungsgebühr erledigst du durch eine Einmalzahlung, sobald dir der
                    Kreditbeitrag von der Bank ausgezahlt wird. Das heißt, dass sie den tatsächlichen Kreditbetrag etwas
                    verkleinert. Es gibt aber auch die Möglichkeit, die Gebühr über die Kreditlaufzeit hinweg
                    abzubezahlen. In dem Fall erhöht sich dein Kreditbeitrag.
                </p>
                <p>
                    Solltest du deinen Kredit vorzeitig tilgen können, ist die Bank verpflichtet, dir auch die
                    verbleibende Bearbeitungsgebühr rückzuerstatten. Das funktioniert recht unkompliziert, indem man die
                    Bank schriftlich auf den eigenen Anspruch der Rückforderung hinweist. Dieses Recht gilt seit dem
                    Jahr 2019.
                </p>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Egal ob wir sie fair finden oder nicht, die Bearbeitungsgebühr ist beid er Kreditaufnahme leider
                    nicht zu umgehen. Jedoch bleibt dir immer die Möglichkeit, zu verhandeln. Damit deine Verhandlungen
                    erfolgreich sind, musst du über alle Nebenkosten gut informiert sein und dir am besten vorher Tipps
                    bei Expert*innen einholen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"bearbeitungsgebuehr"}></BreadcrumbList>
            <ArticleStructuredData
                page={"bearbeitungsgebuehr"}
                heading={"Die Bearbeitungsgebühr – ein Überblick über die Situation in Österreich"}
            />
        </Layout>
    );
};

export default Bearbeitungsgebuehr;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.bearbeitungsgebuehr"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
